import React  from "react";
import Layout from "../components/layout";
import Data from "../config/pointsvisited"
import Edit from "../components/form/Edit";


export default function Page() {
  return (
    <Layout>
      <Edit data={Data()}></Edit>
    </Layout>
  );
}